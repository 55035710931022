import React, { Component } from 'react'
import { Button, Card} from 'react-bootstrap';

export class StaffCard extends Component {
    render() {
        const {name,text,image} = this.props;
        return (
            <div className='item'>
                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={image} />
                    <Card.Body className="text-center">
                        <Card.Title className="text-center">{name}</Card.Title>
                        <Card.Text className="text-muted">
                            {text}
                        </Card.Text>
                        <Button>Go somewhere</Button>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default StaffCard
