import React, { Component } from 'react'
import AssetsCard from './AssetsCard'

export default class StaffSection extends Component {
    render() {
        return (
            <div className="container-fluid px-md-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-12 text-center mb-5">
                        <div className="tophd text-center" >
                            <h2 className="section-heading font-poppins">Our Assets</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mx-md-5">
                    <div className="col-xl-4 col-md-6 col-12 mb-4">
                        <AssetsCard
                            id="1"
                            name=""
                            position=""
                            image="img/default.png"
                            text="We are grateful to all the people of the area for choosing such a cold-headed secretary. After being appointed as the secretary, he only thought about the improvement of the School, when the School has become a priority zone, he left all his work and came to touch only for the progress of education.."
                        />
                    </div>
                    <div className="col-xl-4 col-md-6 col-12 mb-4">
                        <AssetsCard
                                id="2"
                                name="KAMARUJJAMAN SHEIKH"
                                position="(HEAD MASTER)"
                                image="img/default.png"
                                text="One of the best TIC in terms of work and manners. He always creates a good environment for boys and girls to read and listen so that no one has any problem. Very friendly attitude towards students and staffs. He keeps the staff room busy all the time and is a very dear person to the students, teachers and locals.."
                            />
                    </div>
                    <div className="col-xl-4 col-md-6 col-12 mb-4">
                        <AssetsCard
                                id="3"
                                name=""
                                position="Asstt. Superintendent"
                                image="img/default.png"
                                text="He took immediate necessary steps to find a solution to any problem of the staff and students and to make it easier.LIke a team of Staffing Consultants with expertise in solving of problems. He keeps an eye on whether the reading of the School is going on properly. He has won the hearts of everyone with his work skills. "
                            />
                    </div>

                </div>
            </div>
        )
    }
}
