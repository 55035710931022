import React, { Component } from 'react'
import { Button, Navbar, Nav, NavDropdown, Form, FormControl ,Carousel, Card, Container} from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import StaffCard from './StaffCard';


const breakpoint={
    0:{
        items:1,
        nav:true
    },
    576:{
        items:1,
        nav:false
    },
    768:{
        items:2,
        nav:false
    },
    992:{
        items:3,
        nav:true,
        loop:false
    }
}
export default class StaffCarousel extends Component {
   
    componentDidMount(){
       
    }
    render() {
        return (
            
            <section  id="staff-slide" style={{marginTop:'40px', padding:'10px', backgroundColor:'#f1f1f1',position:'relative',zIndex:-2}}>
                <div className="col-12 text-center mb-5 mt-5">
                    <div className="tophd text-center" >
                        <h2 className="section-heading font-poppins">Our Staffs</h2>
                    </div>
                </div>
                <Container  style={{width:'100%',paddingLeft:'40px',paddingRigth:'40px'}}>
                    <div>
                        <OwlCarousel className='owl-theme staffCarousel'  magin={10} loop={true}  autoplayTimeout={3000}
                        responsive={breakpoint}
                        >
                        <StaffCard
                            name= "Jon Doe"
                            text= "Some quick example text to build on the card title and make up the bulk of the card's content."
                            image="img/default.png"
                        />

                        <StaffCard
                            name= "Zarina Adel"
                            text= "Some quick example text to build on the card title and make up the bulk of the card's content."
                            image="img/default.png"
                        />

                        <StaffCard
                            name= "Priyanka Chopra"
                            text= "Some quick example text to build on the card title and make up the bulk of the card's content."
                            image="img/default.png"
                        />

                        <StaffCard
                            name= "Jon Doe"
                            text= "Some quick example text to build on the card title and make up the bulk of the card's content."
                            image="img/default.png"
                        />

                        <StaffCard
                            name= "Zarina Adel"
                            text= "Some quick example text to build on the card title and make up the bulk of the card's content."
                            image="img/default.png"
                        />

                        <StaffCard
                            name= "Priyanka Chopra"
                            text= "Some quick example text to build on the card title and make up the bulk of the card's content."
                            image="img/default.png"
                        />

                        <StaffCard
                            name= "Jon Doe"
                            text= "Some quick example text to build on the card title and make up the bulk of the card's content."
                            image="img/default.png"
                        />

                        <StaffCard
                            name= "Zarina Adel"
                            text= "Some quick example text to build on the card title and make up the bulk of the card's content."
                            image="img/default.png"
                        />

                        <StaffCard
                            name= "Priyanka Chopra"
                            text= "Some quick example text to build on the card title and make up the bulk of the card's content."
                            image="img/default.png"
                        />


                        
                    </OwlCarousel>
                            
                    </div>
                </Container>
            </section>
        )
    }
}

