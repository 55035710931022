import logo from './logo.svg';
import WebFont from 'webfontloader';
import './App.css';
import Header from './components/Header'
import Home from './components/Home';
import Footer from './components/Footer';
import OnlineAdmission from './components/OnlineAdmission';
import OnlineAdmissionNotice from './components/OnlineAdmissionNotice';
import {Route, Switch, BrowserRouter } from "react-router-dom";

import React from 'react';
import Kanyashree from './components/Kanyashree';
import Terms_condition from './components/Terms_condition';
import Privecy from './components/Privecy';

class App extends React.Component {

  componentDidMount(){
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Chilanka','Roboto Slab','Poppins']
      }
    });
  }

  render(){
    return (
      <>
        <Header />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Kanyashree" component={Kanyashree} />
            <Route exact path="/Terms_condition" component={Terms_condition} />
            <Route exact path="/Privacy" component={Privecy} />
            {/* <Route exact path="/OnlineAdmission" component={OnlineAdmission} />
            <Route exact path="/OnlineAdmissionNotice" component={OnlineAdmissionNotice} /> */}
            {/* <Route exact path="/ContactUs" component={ContactUs} />
            <Route exact path="/Registration" component={Registration} />
            <Route exact path="/PrintForm" component={PrintForm} />
            <Route exact path="/Courses" component={Courses} /> */}
          </Switch>
          </BrowserRouter>
        <Footer />
        </>
    );
  }
}

export default App;
