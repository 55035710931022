import React from 'react'
import './Footer.css';
import {Card, Button, Container, Row, Col, CardDeck, CardGroup, Image, Form, InputGroup, ListGroup} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faQuoteLeft, faUserHardHat, faUser, faPhoneAlt, faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagramSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
const Footer = () => {
    return (
           <>
                <footer>
                    <section className="footer-section">
                        <div className="container pt-5">
                            <div className="row">
                                <div className="col-md-3 mt-4 mt-md-0">
                                    <h3 className="footer-title"><span className="footer-title-text">About Us</span></h3>
                                    <h4 className="text-white">Maharajnagar High Madrasah</h4>
                                    <small className="text-white text-muted">Choose your Dream and Make It A Reality .</small>
                                </div>
                                <div className="col-md-3 mt-4 mt-md-0">
                                    <h3 className="footer-title"><span className="footer-title-text">Quick Link</span></h3>
                                    <ul className="quick-link-list">
                                        <li><a href="#"> Online Admission</a></li>
                                        <li><a href="#"> MDM Distribution</a></li>
                                        <li><a href="#"> Notice</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-3 mt-4 mt-md-0">
                                    <h3 className="footer-title"><span className="footer-title-text">Legal</span></h3>
                                    <ul className="quick-link-list">
                                        <li><a href="#"> Term of Use</a></li>
                                        <li><a href="#"> Privacy Policy</a></li>
                                        <li><a href="#"> Cookie Policy</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-3 mt-4 mt-md-0">
                                    <h3 className="footer-title"><span className="footer-title-text">Contact</span></h3>
                                    <p className="text-white">TIC: +91-9876543210</p>
                                    <p className="text-white">Head Clerk: +91-9876543210</p>
                                    <div id="social">
                                        <a className="facebookBtn smGlobalBtn" href="#"></a>
                                        <a className="twitterBtn smGlobalBtn" href="#"></a>
                                        <a className="youtubeBtn smGlobalBtn" href="#"></a>
                                        <a className="linkedinBtn smGlobalBtn" href="#"></a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="footer-section-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 footer-bottom-left text-center text-md-left">
                                    <small className="text-white">©2021 Maharajnagar High Madrasah ( H.S. )</small>
                                </div>
                                <div className="col-md-6 footer-bottom-right text-center text-md-right">
                                    <small className="text-white">Developed by <a href="#">Azizur Rahman</a></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
           </>
    )
}
export default Footer