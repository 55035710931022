import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'
class Carosel extends Component {
    render() {
        return (
            <div  className="slideMargin">
            <Carousel>
                <Carousel.Item>
                    
                        <img
                        className="d-block w-100 slideMain"
                        src="img/slide3.jpg"
                        alt="First slide"
                        style={{height: "70vh"}}
                        />
                    
                    <Carousel.Caption>
                        <h2>OUR MADRASAH</h2>
                        <p>Main Gate Of Our School.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100 slideMain"
                    src="img/slide4.jpg"
                    alt="First slide"
                    style={{height: "70vh"}}
                    />
                    <Carousel.Caption>
                        <h3>OUR SCHOOL BUILDING</h3>
                        <p>Our building in COVID19 sesson.</p> 
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100 slideMain"
                    src="img/slide5.jpg"
                    alt="First slide"
                    style={{height: "70vh"}}
                    />
                    <Carousel.Caption>
                        <h3>OUR STAFFS</h3>
                        <p>Some Special Moments of 2018..</p> 
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100 slideMain"
                    src="img/slide6.jpg"
                    alt="First slide"
                    style={{height: "70vh"}}
                    />
                    <Carousel.Caption>
                        <h3>OUR SCHOOL CAMPUS</h3>
                        <p>Some management for  kids to play in our campus ..</p> 
                    </Carousel.Caption>
                </Carousel.Item>
                
            </Carousel>
        </div>
        );
    }
}

export default Carosel;