import React, { Component } from 'react'
import { Card,Button, Navbar, Nav, NavDropdown, Form, FormControl ,Carousel , Container,Row,Col} from 'react-bootstrap';
import Carosel from './Carosel';
import StaffCarousel from './StaffCarousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faGlobeAmericas, faUserMd, faClipboardList, faUser, faPhoneAlt, faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import GalleryHome from './GalleryHome';
import StaffSection from './StaffSection';

export default class Home extends Component {
    constructor(props) {

        super(props);

        this.state = {
            items: [],
            isLoaded: false
        }

    }
    componentDidMount() {
        localStorage.setItem('sID', null);
        fetch('https://mhmaalimemorial.com/api/getAllNotice.php')
            .then(res => res.json())
            .then(json => {
                this.setState({
                    items: json,
                    isLoaded: true, 
                })
                console.log(json);
            }).catch((err) => {
                console.log("hjhj :",err);
            });

    }
    render() {
        return (
            <div>
                <Carosel />
               {/* <section>
                   <Container fluid>
                       <Row>
                           <Col md={12} className="justify-content-center">
                                <div className="tophd text-center" >
                                    <h2 className="section-heading font-poppins">Quick Links</h2>
                                </div>
                            </Col>
                       </Row>
                       <Row className="justify-content-center mt-5">
                            <Col md={6}>
                                <div className="d-flex justify-content-around"> 
                                <a href="#" target="_blank">
                                    <div className="">
                                        <img src="img/pp.png" width="80" className="d-block mx-auto"></img> 
                                        <p className="quick-link-text">Online Admission</p>
                                    </div>
                                </a>

                                <a href="#" target="_blank">
                                    <div className="">
                                            <img src="img/mdm.png" width="80" className="d-block mx-auto"></img>
                                            <p className="quick-link-text">MDM Distribution</p>
                                    </div>
                                </a>    
                                </div>
                            </Col>
                       </Row>
                   </Container>
               </section> */}
               <section>
                   <Container>
                                <Row>
                           <Col >
                                <Card style={{marginTop:'40px'}} className="cardNotice">
                                <Card.Header className="text-center noticeHd">Notice</Card.Header>
                                    <div style={{padding:'20px',maxHeight: "760px"}} className="overflow-auto">
                                    {this.state.items.map((item,index) => (
                                        <Card key={index} className="notice-card" style={{marginBottom:'15px'}}>
                                            <Row>
                                                <Col md={2} style={{ }}>
                                                    <div style={{padding: "25px"}}>
                                                     <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f7941d" />
                                                    </div>
                                                </Col>
                                                <Col md={10}>
                                                    <Card.Body style={{padding: '5px '}}>
                                                    <Card.Title>

                                                    {item.notice_title}

                                                    {(item.blink)?
                                                        <svg height="100" width="100" className="blinking">
                                                            <circle cx="50" cy="50" r="10" fill="red" />
                                                            Sorry, your browser does not support inline SVG.  
                                                            </svg> 
                                                        :
                                                        ''
                                                    }

                                                    </Card.Title>
                                                    <Card.Text>
                                                    {item.notice_body}
                                                    <p style={{paddingLeft: '20px'}}><a href={item.link}>Click Here .....</a></p>
                                                    </Card.Text>
                                                    </Card.Body>
                                                </Col>
                                            </Row>
                                        </Card>
                                        
                                       
                                    ))}
                                    </div>
                                </Card>
                           </Col>
                       </Row>
                   </Container>
               </section>

               <section id="staff" className="pt-5">
                   <StaffSection/>
               </section>
               <section>
                   <GalleryHome />
               </section>
               <section>
                   <StaffCarousel />
               </section>
               <section  style={{marginTop:0}}>
                    <div className="resultSection">
                        <Container>
                            <div className="col-12 text-center mb-5 mt-5">
                                <div className="tophd text-center" >
                                    <h2 className="section-heading font-poppins">About Institute</h2>
                                </div>
                            </div>
                            <Row>
                                <Col>
                                        <div style={{    textAlign: 'center',padding: '10px'}}>
                                        <p>extends a very warm welcome to the school-going students of Eastern India from Class VII to Class XII. Pathfinder, the leading institute in Eastern India guides students for competitive exams like JEE( main & Advanced), NEET-UG, WBJEE, Olympiads, KVPY and allied competitive exams. We also guide students for Class X and Class XII Board examinations for all Boards. If you are hard-working and sincere and aspire to achieve academic excellence, we shall be happy to nurture and guide you and make your dreams come true.</p>
                                        </div>
                                        <Container style={{marginTop:'50px',paddingBottom:'20px'}}>
                                            <Row>
                                                <Col md={3} className="aboutSection">
                                                    <div className="text-center">
                                                        <div>
                                                            <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f06f24" />
                                                        </div>
                                                        <div>
                                                            <h3>30 YEARS</h3>
                                                            <h5>Experience</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3} className="aboutSection">
                                                    <div className="text-center">
                                                            <div>
                                                                <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f06f24" />
                                                            </div>
                                                            <div>
                                                                <h3>24 K</h3>
                                                                <h5>Happy Students</h5>
                                                            </div>
                                                        </div>
                                                </Col>
                                                <Col md={3} className="aboutSection">
                                                <div className="text-center">
                                                        <div>
                                                            <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f06f24" />
                                                        </div>
                                                        <div>
                                                            <h3>08+</h3>
                                                            <h5>Course</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3} className="aboutSection">
                                                <div className="text-center">
                                                        <div>
                                                            <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f06f24" />
                                                        </div>
                                                        <div>
                                                            <h3>20+</h3>
                                                            <h5>Subjects</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                </Col>
                            </Row>
                        </Container>
                            
                    </div>
               </section>
               <section id="courses" style={{backgroundColor:'#f5f5f5',paddingBottom: '50px',position:'relative',zIndex:-2}}>
                   <Container style={{marginTop:'40px'}}>
                       <Row>
                           <Col md={12}>
                                <div style={{padding:"40px"}}>
                                    <div className="tophd text-center" >
                                        <h2 className="section-heading font-poppins">Our Course</h2>
                                    </div>
                                    <div><center><p style={{padding:"20px"}}>We offers focused concept based learnig approach with personalised feedback to aspiring to aspiring students.</p></center></div>
                                </div>
                                <Container>
                                    <Row className="justify-content-center">
                                    <Col md={4} className="d-flex justify-content-center mb-3">
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Img variant="top" src="img/ict.png" />
                                            <Card.Body>
                                                <Card.Title>Our School Have running an ICT Project</Card.Title>
                                                <Card.Text className="text-muted">
                                                There have Learn First stage Computer all the students there have a computer Lab also (By Exrta Marks)

                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={4} className="d-flex justify-content-center mb-3">
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Img variant="top" src="img/Madrasah.png" />
                                            <Card.Body>
                                                <Card.Title>Our School Serve Class V to Class XII</Card.Title>
                                                <Card.Text className="text-muted">
                                                The equivalent of Higher Secondary Afflated by West Bengal Board of Secondary and Higher Secondary Education
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={4} className="d-flex justify-content-center mb-3">
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Img   variant="top" src="img/Vocational.png" />
                                            <Card.Body>
                                                <Card.Title>Vocational Traning Centre</Card.Title>
                                                <Card.Text className="text-muted">
                                                Our Institute provided Vocational Cources
                                                Like Tailoring, Mobile Reapring, Computer Networking Maintainence and under W.B.S.C.T.V.E & S.D.

                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    </Row>
                                </Container>
                           </Col>
                       </Row>
                   </Container>
               </section>
               <section>
               <Container fluid>
                <Row>
                     <div className="col-12 text-center mb-5 mt-5">
                            <div className="tophd text-center" >
                                <h2 className="section-heading font-poppins">Find Us</h2>
                            </div>
                        </div>
                    <Col>
                    <div style={{width: '100%' }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.6107831212285!2d88.16254991545858!3d25.31727773302994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fadf8198b78b71%3A0x9c0d0d56c8804a8b!2sBaidara%20Janakalyan%20High%20School!5e0!3m2!1sen!2sin!4v1627378933796!5m2!1sen!2sin" width="100%" height="520" style={{border:"0"}} allowFullScreen="" loading="lazy"></iframe>
                    </div>
                    </Col>
                </Row>
            </Container >
            </section>
            </div>
        )
    }
}
