import React, { Component } from 'react'
import "react-image-gallery/styles/css/image-gallery.css";
import { Card,Button, Navbar, Nav, NavDropdown, Form, FormControl ,Carousel , Container,Row,Col} from 'react-bootstrap';

import ImageGallery from 'react-image-gallery';
const images = [
    {
      original: 'img/Galary5.jpg',
      thumbnail: 'img/Galaryth5.png',
      fullscreen: '100%',
    },
    {
      original: 'img/Galary6.jpg',
      thumbnail: 'img/Galaryth6.png',
    },
    {
      original: 'img/Galary7.jpg',
      thumbnail: 'img/Galaryth7.png',
    },
    
  ];
export default class GalleryHome extends Component {
    
    render() {
       
        return (
            <Container  fluid style={{padding:'40px'}}>
                <Row>
                  <div className="col-12 text-center mb-5">
                        <div className="tophd text-center" >
                            <h2 className="section-heading font-poppins">Gallery</h2>
                        </div>
                    </div>
                </Row>
                <Row>
                    <Col>
                        <div style={{width:'100%'}}>
                            <ImageGallery 
                              items={images}
                              showNav={false}
                              autoPlay={true}
                              />
                        </div>
                    </Col>
                </Row>
            </Container>
            
        )
    }
}
