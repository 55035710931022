import React from 'react'

export default function StaffCard(props) {
    return (
        <div className="card text-center rounded-0 border-0" id="card1">
            <div className="hex-img mt-5 mb-4"> <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <pattern id={props.id} patternUnits="userSpaceOnUse" width="100" height="100">
                            <image href={props.image} x="-25" width="150" height="100" />
                        </pattern>
                    </defs>
                    <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={`url(#${props.id})`} />
                </svg> </div>
            <h2 className="card-name">{props.name}</h2>
            <p className="position">{props.position}</p>
            <div className="row mx-3 mb-5 mt-3 justify-content-center small-text">
                <small className="text-muted">
                    {props.text}
                </small>
            </div>
        </div>
    )
}
